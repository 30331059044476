
export default {
  name: 'ToolTipIcon',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-star'
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    iconColor () {
      return this.color
    }
  }
}
